import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from './store';
import { storeToRefs } from 'pinia';
import { CmSalesImpactProductsDetails } from '@/api/openapi';

interface Layout {
  isLoading: Ref<boolean>;
  isLoadingReport: Ref<boolean>;
  product: Ref<CmSalesImpactProductsDetails>;
}
export const useLayout = (): Layout => {
  const store = useStore();
  const { product, ...storeRef } = storeToRefs(store);
  const { fetchStore, fetchStoreReport } = store;
  const route = useRoute();
  const { params } = route;
  const productId = ref(Number(params.productId));
  const companyId = ref(Number(params.companyId));
  const reportId = ref(Number(params.reportId) || undefined);

  // 商品の取得
  (async () => {
    if (productId.value !== product.value.productId) {
      await fetchStore({
        productId: productId.value,
        companyId: companyId.value
      });
    }
    if (reportId.value) {
      await fetchStoreReport({
        companyId: companyId.value,
        reportId: reportId.value
      });
    }
  })();

  return {
    product,
    ...storeRef
  };
};
