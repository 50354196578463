import { ref } from 'vue';
import { defineStore } from 'pinia';
import { CmsiApi } from '@/api';
import {
  CmSalesImpactProductsDetails,
  CmSalesImpactReportsDetails,
  CmSalesImpactGraphData
} from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { httpCode } from '@/common/constant';

export const useStore = defineStore('cmsi', () => {
  const product = ref({} as CmSalesImpactProductsDetails);
  const report = ref({} as CmSalesImpactReportsDetails);

  const [isLoading, fetchStore] = useLoading(_fetchStore);
  const [isLoadingReport, fetchStoreReport] = useLoading(_fetchStoreReport);
  const [isLoadingGraph, fetchStoreGraphs] = useLoading(_fetchStoreGraphs);

  async function _fetchStore({
    productId,
    companyId
  }: {
    productId: number;
    companyId: number;
  }) {
    // 商品の取得
    try {
      const { data } = await CmsiApi.getCmSalesImpactProductsProductIdDetails(
        productId,
        companyId
      );
      product.value = data;
    } catch (e) {
      if (e.status === httpCode.forbidden) return;
      toast({
        title: '商品情報の取得に失敗',
        variant: 'error'
      });
    }
  }
  async function _fetchStoreReport({
    reportId,
    companyId
  }: {
    reportId: number;
    companyId: number;
  }): Promise<CmSalesImpactReportsDetails> {
    // レポートを取得
    try {
      const { data } = await CmsiApi.getCmSalesImpactReportsReportIdDetails(
        reportId,
        companyId
      );
      report.value = data;
      return data;
    } catch (e) {
      if (e.status === httpCode.forbidden)
        return {} as CmSalesImpactReportsDetails;
      toast({
        title: 'レポート詳細を取得失敗',
        variant: 'error'
      });
      return {} as CmSalesImpactReportsDetails;
    }
  }

  async function _fetchStoreGraphs({
    reportId,
    companyId,
    productId,
    reachWeeks,
    reachFrequency,
    areaCode
  }: {
    reportId?: number | undefined;
    companyId: number;
    productId: number;
    reachWeeks?: number | undefined;
    reachFrequency?: number | undefined;
    areaCode?: string | undefined;
  }): Promise<CmSalesImpactGraphData> {
    // 最新のレポートを取得
    try {
      const { data } = await CmsiApi.getCmSalesImpactGraphData(
        productId,
        companyId,
        reportId,
        undefined,
        undefined,
        reachWeeks,
        reachFrequency,
        areaCode
      );
      return data;
    } catch (e) {
      if (e.status === httpCode.forbidden || e.name === 'RequiredError')
        return {} as CmSalesImpactGraphData;
      toast({
        title: 'レポートグラフデータの取得失敗',
        variant: 'error'
      });
      return {} as CmSalesImpactGraphData;
    }
  }

  return {
    product,
    report,
    isLoading,
    isLoadingGraph,
    isLoadingReport,
    fetchStore,
    fetchStoreReport,
    fetchStoreGraphs
  };
});
